import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { useQueryCache, useQuery } from "react-query"
import { useToasts } from "react-toast-notifications"
import { Link } from "gatsby"

import Profile from "../components/profile"
import SessionContext from "./../context/session"
import { faunaQueries } from "../fauna/query-manager"
import { safeVerifyError } from "../fauna/helpers/errors"
import DecksBar from "../components/decksBar"
import UIContextImported from "./../context/ui"
import { logoutBecauseUnauthorized } from "../fauna/helpers/util"
import DeckRow from "../components/DeckRow"
import Spinner from "../components/Spinner"
import SEO from "./../components/seo"

const Decks = props => {
  const { addToast } = useToasts()
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state

  const UIContext = useContext(UIContextImported)
  const { state: uistate, dispatch: uidispatch } = UIContext
  const {
    withinDays,
    deckFormat,
    deckColor,
    cardsIncluded,
    cardsExcluded,
    orderByUDF,
  } = uistate

  const queryCache = useQueryCache()
  const queryInfo = use_get_decks_all(
    queryCache,
    addToast,
    logoutBecauseUnauthorized,
    sessionContext,
    withinDays,
    deckFormat,
    deckColor,
    cardsIncluded,
    cardsExcluded,
    orderByUDF
  )
  /*   console.log("Decks/queryInfo: ", queryInfo) */

  useEffect(() => {
    if (!user && faunaQueries.getUser()) {
      sessionContext.dispatch({ type: "login", data: faunaQueries.getUser() })
    }
    return function cleanup() {
      /*       console.log("decks/unmounting") */
    }
  }, [user, sessionContext])

  return (
    <>
      <SEO
        slug="/decks/"
        title="Filter MTG decks and understand the meta"
        description="List of MTG decks. Filter w/ or w/o any card. When a new deck is created, discord bots will post its URL in the correspondent bot-only channel"
        breadName="Decks"
      />
      <Profile />
      <BackgroundRadial>
        <DecksBar />
        {queryInfo.isLoading ? (
          <Spinner />
        ) : queryInfo.isSuccess && queryInfo.data?.myError ? (
          <h1>ERROR D001</h1>
        ) : queryInfo.isSuccess && !queryInfo.data ? (
          <h1>ERROR D002</h1>
        ) : queryInfo.isSuccess && queryInfo.data ? (
          <Flex_Decks>
            {queryInfo.data.data.map(deck => (
              <Link
                to={`/deck/${deck.deckRef.value.id}`}
                key={deck.deckRef.value.id}
              >
                <DeckRow {...deck} />
              </Link>
            ))}
          </Flex_Decks>
        ) : queryInfo.isIdle ? (
          <h1>ERROR D003</h1>
        ) : queryInfo.isError ? (
          <h1>ERROR D004</h1>
        ) : (
          <h1>ERROR D005</h1>
        )}
      </BackgroundRadial>
    </>
  )
}

export default Decks

function use_get_decks_all(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext,
  withinDays,
  deckFormat,
  deckColor,
  cardsIncludedObjects,
  cardsExcludedObjects,
  orderByUDF
) {
  const cardsIncluded = cardsIncludedObjects
    .map(cardObj => {
      if (cardObj.isFiltering) {
        return cardObj.name
      }
    })
    .filter(el => el)
  const cardsExcluded = cardsExcludedObjects
    .map(cardObj => {
      if (cardObj.isFiltering) {
        return cardObj.name
      }
    })
    .filter(el => el)
  return useQuery(
    [
      `QM_get_decks_all`,
      `QM_get_decks_all-${orderByUDF}-${withinDays}-${deckFormat}-${deckColor}-INC:${cardsIncluded}-EXC:${cardsExcluded}`,
    ],
    (prefix, key, after = null) =>
      fetch_get_decks_all(
        queryCache,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext,
        after,
        withinDays,
        deckFormat,
        deckColor,
        cardsIncluded,
        cardsExcluded,
        orderByUDF
      ),
    {
      staleTime: 0,
      /* keepPreviousData: true, */

      onError: (error, values, rollback) => {
        /*         console.log("use_get_decks_all/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied"
        ) {
          logoutBecauseUnauthorized(sessionContext, addToast, error.error)
        } else {
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
      },
      onSuccess: (data, values) => {
        /*         console.log("use_get_decks_all/onSuccess: ", data) */
        data.data.forEach(singleDeck => {
          let singleDeckMod = {}
          if (typeof singleDeck["first"] === "number") {
            singleDeckMod = {
              ...singleDeck,
              likes: singleDeck["first"],
              created: singleDeck["second"],
            }
          } else {
            singleDeckMod = {
              ...singleDeck,
              likes: singleDeck["second"],
              created: singleDeck["first"],
            }
          }
          queryCache.setQueryData(
            ["initial_deck", singleDeck.deckRef.value.id],
            singleDeckMod
          )
        })
      },
    }
  )
}

async function fetch_get_decks_all(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext,
  after,
  withinDays,
  deckFormat,
  deckColor,
  cardsIncluded,
  cardsExcluded,
  orderByUDF
) {
  try {
    const response = await faunaQueries.QM_get_decks_all(
      1,
      after,
      null,
      withinDays,
      deckFormat,
      deckColor,
      cardsIncluded,
      cardsExcluded,
      orderByUDF
    )
    /*     console.log("fetch_get_decks_all -> response", response) */
    return response
  } catch (err) {
    /*     console.log("fetch_get_decks_all -> err", err) */
    /* logoutBecauseUnauthorized(sessionContext, addToast, err) */
    throw err
  }
}

const BackgroundRadial = styled.div`
  background: radial-gradient(#555, #111);
  min-height: 100vh;
`

const Flex_Decks = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem;
`
